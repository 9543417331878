import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)


const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/user',
        name: 'UserInfo',
        component: () =>
            import ('../views/UserInfo.vue')
    },
    {
        path: '/level',
        name: 'Level',
        component: () =>
            import ('../views/Level.vue')
    },
    {
        path: '/list',
        name: 'List',
        component: () =>
            import ('../views/List.vue')
    },
    {
        path: '/success',
        name: 'Success',
        component: () =>
            import ('../views/Success.vue')
    },
    {
        path: '/fail',
        name: 'Fail',
        component: () =>
            import ('../views/Fail.vue')
    },
    {
        path: '/charts',
        name: 'Charts',
        component: () =>
            import ('../views/Charts.vue')
    },
    {
        path: '/share',
        name: 'Share',
        component: () =>
            import ('../views/Share.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router