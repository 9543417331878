<template>
  <div class="container" v-if="show">
    <div class="text">
      <div class="flex">
        <div class="tl">1.</div>
        <div class="flexItem">
          挑战赛总共设置4个关卡，每关挑战成功即可获得对应的大奖；
        </div>
      </div>
      <div class="flex">
        <div class="tl">2.</div>
        <div class="flexItem">
          每闯过一关，参赛者可以选择继续挑战或终止挑战；终止挑战即可获得已完成关卡的对应奖品，如果选择继续挑战但是挑战下一关失败，就无法获得任何奖品啦。
        </div>
      </div>
      <div class="flex">
        <div class="tl">3.</div>
        <div class="flexItem">排行榜前三名的超级品牌官将获得额外超级大奖！</div>
      </div>
    </div>
    <div class="closeBtn" @click="show = !show">
      <img src="../assets/images/close.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Rule",
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent url("../assets/images/bg1.png") no-repeat scroll 0 0;
  background-size: 100% 100%;
  .text {
    width: 82vw;
    height: 60vh;
    background: transparent url("../assets/images/yxgzbg.png") no-repeat scroll
      0 0;
    background-size: 100% 100%;
    position: absolute;
    left: 9vw;
    top: 18vh;
    color: #fff;
    font-size: 14px;
    line-height: 1.8;
    padding: 10.7vh 8.9vw 0 7.3vw;
    text-align: left;
    .flex{
        align-items: flex-start;
        &+.flex{
            margin-top: 1vh;
        }
    }
  }
  .closeBtn {
    width: 15vw;
    position: absolute;
    left: 41.8vw;
    top: 78vh;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>