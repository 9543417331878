import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
    levelText,
    examId
} from '../public/config.js'
import './assets/css/style.css'
import 'vant/lib/index.css';
import {
    Toast
} from 'vant';
import Vant from 'vant';

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.Toast = Toast
Vue.prototype.levelTextObj = levelText
Vue.prototype.examId = examId



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')